import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import VueHead from "vue-head";

createApp(App)
  .use(VueHead)
  .use(VueScrollTo,{
    offset: -100
  })
  .use(store)
  .use(router)
  .mount("#app");