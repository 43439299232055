<template>
  <section>
    <div class="wrap">
      <div class="title">
        <h2 id="merit">都市ガス電気に変えると<br class="sp-only">断然オトク！</h2>
        <img src="@/assets/title-bar.webp" class="title-bar">
        <img src="@/assets/ira002.webp" class="ira-img pc-tablet-only">
      </div>
      <div class="card-contents">
        <div class="card">
          <div class="card-img">
            <img src="@/assets/icon001.webp" alt="お申し込み時の初期費用無料！">
          </div>
          <h3 class="card-title">申し込み時の<br class="pc-tablet-only"/>初期費用が0円！</h3>
          <p>新電力でありがちな申込時の事務手数料は一切なし！ 0円で月々のコスト削減を始めましょう。</p>
        </div>
        <div class="card">
          <div class="card-img">
            <img src="@/assets/icon002.webp" alt="簡単切り替え！">
          </div>
          <h3 class="card-title">申し込みは<br class="pc-tablet-only"/>カンタン切り替え！</h3>
          <p>申込は3分で完結♪ ご契約中の電力会社の明細があれば2分で記入完了です。</p>
        </div>
        <div class="card">
          <div class="card-img">
            <img src="@/assets/icon003.webp" alt="基本料金を大幅削減！">
          </div>
          <h3 class="card-title">基本料金の削減</h3>
          <p>電気の使用量に問わず基本料金の項目が地域電力会社価格より20%〜50%オフ！</p>
        </div>
        <div class="card">
          <div class="card-img">
            <img src="@/assets/icon004.webp" alt="一人暮らしをサポート" class="small">
          </div>
          <h3 class="card-title">一人暮らしの安心を<br />サポートするサービスが充実</h3>
          <p>警察OB・OGの専門の相談員がトラブル解決をサポートいたします。</p>
        </div>
        <div class="card">
          <div class="card-img">
            <img src="@/assets/icon005.webp" alt="切り替え後も安定供給" class="small">
          </div>
          <h3 class="card-title">切り替えをしても<br/>変わらず安定供給！</h3>
          <p>現在使用している送電網をそのまま利用するため、停電等のトラブルが増えることはありません。</p>
        </div>
        <div class="card mb-0">
          <div class="card-img">
            <img src="@/assets/icon006.webp" alt="電気使用量がリアルタイムで確認可能！">
          </div>
          <h3 class="card-title">電気の使用状況が<br/>リアルタイムで見える！</h3>
          <p>無料のWeb明細のご選択で30分ごとの電気使用量がリアルタイムで見えます！</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ChangeVue"
}
</script>

<style lang="scss" scoped>
  section {
    background-color: var(--gray);
    .wrap {
      .title {
        position: relative;
        .ira-img {
          position: absolute;
          top: -30px;
          right: 12%;
          width: 120px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            right: 5%;
            width: 80px;
          }
        }
      }
      .card-contents {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 60px;
        @media screen and (max-width: 767px) {
          margin-top: 40px;
        }
        .card {
          width: 30%;
          height: 600px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 32%;
            height: 470px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
            margin-bottom: 40px;
          }
          .card-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            aspect-ratio: 1/1;
            background-color: var(--white);
            @media screen and (max-width: 767px) {
              width: 80%;
              margin: auto;
            }
            img {
              width: 75%;
            }
            .small {
              width: 60%;
            }
          }
          .card-title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 75px;
            font-size: 25px;
            font-weight: 500;
            line-height: 2.3rem;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 10px;
            color: var(--sub1);
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              height: 60px;
              font-size: 18px;
              line-height: 20px;
              margin-top: 10px;
              margin-bottom: 10px;
            }
            @media screen and (max-width: 767px) {
              height: auto;
              font-size: 21px;
              line-height: 25px;
              margin-top: 20px;
              margin-bottom: 10px;
            }
          }
        }
        .mb-0 {
          margin-bottom: 0;
        }
      }
    }
  }
</style>