<template>
    <div>
        <PageTitle :title="this.title" :subTitle="this.subTitle"/>
        <section>
            <div class="wrap">
                <h2>個人情報保護方針</h2>
                <div class="privacy-content">
                    <p>当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。</p>
                    <ol>
                        <li>個人情報は、電力小売事業などにおける当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。</li>
                        <li>個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</li>
                        <li>個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、万一の際には速やかに是正措置を講じます。</li>
                        <li>個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。</li>
                        <li>個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。</li>
                    </ol>
                    <p>本方針は、全ての従業者に配付して周知させるとともに、当社のホームページ、パンフレットなどに掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。</p>
                    <p class="right my-10">以上</p>

                    <div class="information">
                        <h3>【お問合せ窓口】</h3>
                        <p>個人情報に関するお問合せにつきましては、下記窓口で受付けております。</p>
                        <p class="bold">都市ガス電気  個人情報問合せ窓口</p>
                        <p>〒810-0001 福岡県福岡市中央区天神2丁目3-10天神パイクレント719</p>
                        <p>TEL: <a href="tel:0922875096" class="phone-number">092-287-5096</a></p>
                        <p>Email: toshigas-denki@kyusyu-com.site</p>
                    </div>

                    <p class="right">制定 2018年4月3日</p>
                    <p class="right">株式会社通信基地局</p>
                    <p class="right">代表取締役 平田将也</p>
                </div>

                <h2>個人情報の取扱いについて</h2>
                <div class="privacy-content">
                    <ol>
                        <li>
                            <p class="bold">個人情報の管理者の氏名又は職名、所属及び連絡先</p>
                            <p class="indent">管理者の職名と氏名：都市ガス電気 平田将也</p>
                            <p class="indent">連絡先：電話 092-287-5096</p>
                        </li>

                        <li>
                            <p class="bold">個人情報の利用目的</p>
                            <div class="indent">
                                <p>（1） ご本人より書面等（Webページや、電子メール等によるものを含む。以下「書面」）により直接取得する個人情報</p>
                                <div class="indent">
                                    <p>① お客さま情報</p>
                                    <ul>
                                        <li>お見積のご依頼・ご相談に対する回答及び資料送付</li>
                                        <li> 各種商品・サービスに関する情報提供</li>
                                        <li>メールマガジンの提供お問合せ対応のため</li>
                                    </ul>
                                    <p>② お取引様情報</p>
                                    <ul>
                                        <li>商談及び業務上の諸連絡、受発注業務、請求支払業務のため</li>
                                    </ul>
                                    <p>③ 当社従業者情報(退職者情報含む)</p>
                                    <ul>
                                        <li>従業者の人事労務管理、業務管理、健康管理、セキュリティ管理のため</li>
                                    </ul>
                                    <p>④ 個人番号</p>
                                    <ul>
                                        <li>「給与所得・退職所得の源泉徴収票作成事務」「健康保険・厚生年金保険届出事務」「雇用保険届出事務」「労働者災害補償保険法に基づく請求に関する事務」「国民年金の第3号被保険者の届出に関する事務」のため</li>
                                    </ul>
                                    <p>⑤ 採用応募者に関する個人情報</p>
                                    <ul>
                                        <li>採用応募者への連絡と当社の採用業務管理のため</li>
                                    </ul>
                                    <p>⑥ お問合せ情報</p>
                                    <ul>
                                        <li>お問合せのご返答、お知らせをお送りするため</li>
                                    </ul>
                                    <div class="information">
                                        <h3>【クレジットカード情報を含む個人情報に関して】</h3>
                                        <p>当社がお客さまからクレジットカード情報（カード名義・カード番号・有効期間）を取得する目的、クレジット情報の取得者名、保存期間は次の通りです。</p>
                                        <p>(利用目的) 当社サービスの代金を決済するため</p>
                                        <p>(情報の取得者) ヱビス電力 個人情報保護管理者</p>
                                        <p>(情報の提供先名) ご利用の各カード会社</p>
                                        <p>(保存期間) 当社ではクレジットカード情報は契約期間保有します</p>
                                    </div>
                                </div>
                                <p>（2） ご本人より書面以外で直接取得する個人情報</p>
                                <div class="indent">
                                    <p>① 通話内容を記録あるいは録音で取得する個人情報</p>
                                    <ul>
                                        <li>お取引やお問い合わせの対応及びトラブル等を防止するため</li>
                                    </ul>
                                    <p>② 代理店等から提供された個人情報</p>
                                    <ul>
                                        <li>電力小売事業などを適切に遂行するため</li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li>
                            <p class="bold">個人情報の第三者提供</p>
                            <div class="indent">
                                <p>当社では、次に掲げる場合を除き、取得した個人情報を第三者に提供することはございません。</p>
                                <ol>
                                    <li>あらかじめご本人様の同意を得ている場合</li>
                                    <li>法令に基づく場合</li>
                                    <li>人の生命、身体又は財産の保護のために必要がある場合であって、ご本人様の同意を得ることが困難な場合</li>
                                    <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご本人様の同意を得ることが困難な場合</li>
                                    <li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
                                    <li>業務を円滑に遂行するため、利用目的の達成に必要な範囲内で個人情報の取扱いの全部又は一部を委託する場合</li>
                                    <li>下記４. に示す共同利用する場合</li>
                                </ol>
                            </div>
                        </li>

                        <li>
                            <p class="bold">個人情報の共同利用</p>
                            <div class="indent">
                                <p>当社では、次の要領で個人情報を共同利用いたします。</p>
                                <ol>
                                    <li>
                                        <p>共同利用する者の範囲</p>
                                        <div class="indent">
                                            <p>当社は以下の者との間でお客さまの個人情報を共同で利用することがあります※1</p>
                                            <ul>
                                                <li>小売電気事業者※2</li>
                                                <li>一般送配電事業者※3</li>
                                                <li>電力広域的運営推進機関</li>
                                                <li>需要抑制契約者※4</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <p>共同利用の目的</p>
                                        <ol>
                                            <li>託送供給契約又は電力量調整供給契約（以下「託送供給等契約」といいます。）の締結、変更又は解約のため</li>
                                            <li>小売供給契約(離島供給及び最終保障供給に関する契約を含む。)又は電気受給契約(以下「小売供給等契約」といいます。)の廃止取次※5のため</li>
                                            <li>供給（受電）地点に関する情報の確認のため</li>
                                            <li>電力量の検針、設備の保守・点検・交換、停電時・災害時等の設備の調査その他の託送供給等契約に基づく一般送配電事業者の業務遂行のため</li>
                                            <li>ネガワット取引に関する業務遂行のため</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p>共同利用する情報項目</p>
                                        <ol>
                                            <li>基本情報：氏名、住所、電話番号及び小売供給等契約の契約番号</li>
                                            <li>供給（受電）地点に関する情報：託送供給等契約を締結する一般送配電事業者の供給区域、離島供給約款対象、供給（受電）地点特定番号、託送契約高情報、電流上限値、接続送電サービスメニュー、力率、供給方式、託送契約決定方法、計器情報、引込柱番号、系統連系設備有無、託送契約異動年月日、検針日、契約状態、廃止措置方法</li>
                                            <li>ネガワット取引に関する情報：発電販売量、需要調達量、需要抑制量、ベースライン</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p>共同利用する個人情報の管理について責任を有する者の名称</p>
                                        <ol>
                                            <li>基本情報：小売供給等契約を締結している小売電気事業者<br>(但し、離島供給又は最終保障供給を受けている需要者に関する基本情報については、一般送配電事業者)</li>
                                            <li>供給（受電）地点に関する情報：供給(受電)地点を供給区域とする一般送配電事業者</li>
                                            <li>ネガワット取引に関する情報：需要抑制契約者</li>
                                        </ol>
                                    </li>
                                </ol>
                                <div class="my-10">
                                    <p class="small">※1… 当社は、共同利用の目的のために必要な範囲の事業者に限定してお客さまの個人情報を共同利用するものであり、必ずしも全ての小売電気事業者、一般送配電事業者及び需要抑制契約者との間でお客さまの個人情報を共同利用するものではありません。</p>
                                    <p class="small">※2… 小売電気事業者とは、電気事業法(昭和39年7月11日法律第170号)第2条の5第1項に規定する登録拒否事由に該当せず、小売電気事業者として経済産業大臣の登録を受けた事業者(電気事業法等の一部を改正する法律(平成26年法律第72号)の附則により、小売電気事業者の登録を受けたとみなされた事業者を含みます。)をいいます(事業者の名称、所在地等につきましては、資源エネルギー庁のホームページをご参照ください。)</p>
                                    <p class="small">※3… 一般送配電事業者とは、北海道電力株式会社、東北電力株式会社、東京電力パワーグリッド株式会社、中部電力株式会社、北陸電力株式会社、関西電力株式会社、中国電力株式会社、四国電力株式会社、九州電力株式会社及び沖縄電力株式会社をいいます。</p>
                                    <p class="small">※4… 需要抑制契約者とは、一般送配電事業者たる会員との間で需要抑制量調整供給契約を締結している事業者(契約締結前に事業者コードを取得している事業者を含みます)をいいます。（事業者の名称、所在地等については、電力広域的運営推進機関のホームページをご参照ください）。</p>
                                    <p class="small">※5… 「小売供給等契約の廃止取次」とは、お客さまから新たに小売供給等契約の申込みを受けた事業者が、お客さまを代行して、既存の事業者に対して、小売供給等契約の解約の申込みを行うことをいいます。</p>
                                </div>
                            </div>
                        </li>

                        <li>
                            <p class="bold">個人情報取扱いの委託</p>
                            <p class="indent">
                                当社は事業運営上、お客さまにより良いサービスを提供するために業務の一部を外部に委託しています。業務委託先に対しては、個人情報を預託する場合があります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などによりお客さまの個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。
                            </p>
                        </li>

                        <li>
                            <p class="bold">個人情報の開示等の請求</p>
                            <p class="indent">
                                当社は、ご本人からのご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止）の求めに応諾いたします。開示等の求めは当社問合わせ窓口にお申し出下さい。その際、当社はご本人を確認させていただいたうえで、合理的な期間内に対応いたします。取引先から委託される個人情報は開示対象個人情報に該当いたしませんが、取引先より特段の指示があった場合はその指示に従います。開示等の申し出の詳細につきましては、当社ホームページ掲載の「開示対象個人情報の請求手続きについて」をご覧ください。
                            </p>
                        </li>

                        <li>
                            <p class="bold">個人情報を提供されることの任意性と結果</p>
                            <p class="indent">
                                当社への個人情報の提供は、ご本人の任意によるものです。 ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。
                            </p>
                        </li>

                        <li>
                            <p class="bold">ご本人が容易に認識できない方法による個人情報の取得について</p>
                            <p class="indent">
                                クッキー（Cookies）は、ご利用者が当社のサイトに再度訪問された際、より便利に当サイトを閲覧していただくためのものであり、プライバシーを侵害するものではありません。またご利用されているコンピューターへ悪影響を及ぼすことはありません。<br>
                                当社ではクッキー（Cookies）等により、ご本人が容易に認識できない方法による個人情報の取得は行っておりません。<br>
                                また当社のサイトでは個人情報を入力していただく部分にはすべてSSL（Secure Sockets Layer）のデータ暗号化システムを利用しております。さらに、サイト内における情報の保護にもファイアウオールを設置するなどの方策を採っております。ただし、インターネット通信の性格上、セキュリティを完全に保証するものではありません。あらかじめご了承ください。
                            </p>
                        </li>

                        <li>
                            <p class="bold">認定個人情報保護団体について</p>
                            <p class="indent">
                                認定個人情報保護団体とは、個人情報保護法第3７条に基づき主務大臣の認定を受けた団体で、対象個人情報の取扱いに関する苦情処理、対象事業者への情報提供等を通じ個人情報の適正な取扱いの確保を目的とする団体です。
                            </p>
                        </li>
                    </ol>

                    <div class="information">
                        <h3>【お問合せ窓口】</h3>
                        <p>個人情報に関するお問合せにつきましては、下記窓口で受付けております。</p>
                        <p class="bold">都市ガス電気  個人情報問合せ窓口</p>
                        <p>〒810-0001 福岡県福岡市中央区天神2丁目3-10天神パイクレント719</p>
                        <p>TEL: <a href="tel:0922875096" class="phone-number">092-287-5096</a></p>
                        <p>Email: toshigas-denki@kyusyu-com.site</p>
                    </div>
                </div>

                <h2>開示対象個人情報の請求手続きについて</h2>
                <div class="privacy-content">
                    <p>当社で保有している開示対象個人情報に関して、ご本人様又はその代理人様からの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、当社所定の請求書により、以下の手続きにて対応させていただきます。</p>
                    <h3 class="my-10">《個人情報の利用目的》</h3>
                    <p class="bold">① お客さま情報</p>
                    <ul class="m-0">
                        <li>お見積のご依頼・ご相談に対する回答及び資料送付</li>
                        <li>各種商品・サービスに関する情報提供</li>
                        <li>メールマガジンの提供お問合せ対応のため</li>
                    </ul>
                    <p class="bold">② お取引様情報</p>
                    <ul class="m-0">
                        <li>商談及び業務上の諸連絡、受発注業務、請求支払業務のため</li>
                    </ul>
                    <p class="bold">③ 当社従業者情報(退職者情報含む)</p>
                    <ul class="m-0">
                        <li>従業者の人事労務管理、業務管理、健康管理、セキュリティ管理のため</li>
                    </ul>
                    <p class="bold">④ 個人番号</p>
                    <ul class="m-0">
                        <li>「給与所得・退職所得の源泉徴収票作成事務」「健康保険・厚生年金保険届出事務」「雇用保険届出事務」「労働者災害補償保険法に基づく請求に関する事務」「国民年金の第3号被保険者の届出に関する事務」のため</li>
                    </ul>
                    <p class="bold">⑤ 採用応募者に関する個人情報</p>
                    <ul class="m-0">
                        <li>採用応募者への連絡と当社の採用業務管理のため</li>
                    </ul>
                    <p class="bold">⑥ お問合せ情報</p>
                    <ul class="m-0">
                        <li>お問合せのご返答、お知らせをお送りするため</li>
                    </ul>

                    <ol>
                        <li>
                            <p class="bold">「開示等の請求」申出先</p>
                            <div class="indent">
                                <p>
                                    「開示等の請求」は下記宛、当社所定の請求書に必要書類を同封の上、郵送によりお願い申し上げます。<br>
                                    なお、「利用目的の通知」または「開示請求」の場合は「４」に記された手数料を同封して下さい。
                                </p>
                                <div class="information">
                                    <h3>【苦情・相談窓口】</h3>
                                    <p class="bold">都市ガス電気  個人情報問合せ窓口</p>
                                    <p>〒810-0001 福岡県福岡市中央区天神2丁目3-10天神パイクレント719</p>
                                    <p>TEL: <a href="tel:0922875096" class="phone-number">092-287-5096</a></p>
                                    <p>Email: toshigas-denki@kyusyu-com.site</p>
                                </div>
                            </div>
                        </li>

                        <li>
                            <p class="bold">「開示等の請求」に際してご提出いただく書類</p>
                            <div class="indent">
                                <p>「開示等の請求」を行う場合は、(1)の請求書に所定の事項を全てご記入の上、(2)を同封しご郵送下さい。なお、請求書用紙につきましては、弊社にご連絡いただければ郵送させていただきます。</p>
                                <ol>
                                    <li>
                                        <p class="bold">当社所定の請求書</p>
                                        <p>お申し出受付け後、当社からご利用いただく所定の請求書様式「開示対象個人情報開示等請求書」を郵送いたします。</p>
                                    </li>
                                    <li>
                                        <p class="bold">ご本人様確認と必要書類</p>
                                        <p>ご本人様確認のため、当社にご提供いただいている個人情報のうち、ご本人確認可能な2項目程度（例：電話番号と生年月日等）の情報を当社からお問合せさせていただきます。なお、重要な個人情報と判断される場合は、以下の本人確認書類のいずれかの写しの送付をお願いする場合があります。その場合はあらためて当社よりご通知します。ご送付にあたっては、現住所が分かるものを、本籍部分は黒塗りにして送付して下さい。</p>
                                        <p>① 運転免許証</p>
                                        <p>② パスポート</p>
                                        <p>③ 住民基本台帳カード</p>
                                        <p>④ 健康保険の被保険者証</p>
                                        <p>⑤ その他本人確認できる公的書類</p>
                                    </li>
                                </ol>
                            </div>
                        </li>
                        <li>
                            <p class="bold">代理人様による「開示等の請求」の場合</p>
                            <p>「開示等の請求」をする方が代理人様である場合は、2. の（1）の書類に加えて、下記3.（1）の代理人である事を証明する書類の写しのいずれか及び3. （2）代理人様ご自身を証明する書類の写しのいずれかを同封してください。ご送付にあたっては、現住所が分かるものを、本籍部分は黒塗りにして送付して下さい。</p>
                            <p class="bold">（1） 代理人である事を証明する書類</p>
                            <div class="indent">
                                <p>＜開示等の求めをすることにつき本人が委任した代理人様の場合＞</p>
                                <p class="indent">① 本人の委任状（原本）</p>
                                <p>＜代理人様が未成年者の法定代理人の場合＞</p>
                                <p class="indent">① 戸籍謄本</p>
                                <p class="indent">② 住民票（続柄の記載されたもの）</p>
                                <p class="indent">③ その他法定代理権の確認ができる公的書類</p>
                                <p>＜代理人様が成年被後見人の法定代理人の場合＞</p>
                                <p class="indent">① 後見登記等に関する登記事項証明書</p>
                                <p class="indent">② その他法定代理権の確認ができる公的書類</p>
                            </div>
                            <p class="bold">（2） 代理人様ご自身を証明する書類の写し</p>
                            <p class="indent">① 運転免許証</p>
                            <p class="indent">② パスポート</p>
                            <p class="indent">③ 健康保険の被保険者証</p>
                            <p class="indent">④ 住民票</p>
                            <p class="indent">⑤ 住民基本台帳カード</p>
                        </li>

                        <li>
                            <p class="bold">「利用目的の通知」または「開示請求」の手数料及びその徴収方法</p>
                            <p>利用目的の通知又は開示の請求の場合にのみ、1回の請求につき、以下の金額（当社からの返信費を含む）を申し受けます。下記金額分の郵便定額小為替を請求書類の郵送時に同封してください。</p>
                            <p>手数料金額：1,000円</p>
                        </li>

                        <li>
                            <p class="bold">「開示等の請求」に対する回答方法</p>
                            <p>原則として、請求書記載のご本人様住所宛に書面（封書郵送）にてご回答申し上げます。</p>
                            <p>◇「開示等の請求」にともない取得した個人情報は、開示等の請求への対応に必要な範囲に限り取り扱います。</p>
                            <p>以下の場合には、「開示等の請求」にお応えできない場合があります。その場合は、その旨と理由をご通知申し上げます。また、不開示の場合についても手数料をいただきますのでご承知おきください。</p>
                            <div class="my-10">
                                <p>① ご本人様又は代理人様の本人確認できない場合</p>
                                <p>② 所定の申請書類に不備があった場合</p>
                                <p>③ 開示等の請求の対象が「開示対象個人情報」(下記※)に該当しない場合</p>
                                <p>④ ご本人様又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</p>
                                <p>⑤ 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p>
                                <p>⑥ 法令に違反することとなる場合</p>
                            </div>
                            <div class="my-10">
                                <p>※ 開示対象個人情報とは、体系的に構成した情報の集合物を構成する個人情報であって、当社が、ご本人から求められる開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の求めのすべてに応じることができる権限を有するものです。ただし、以下a)～d)のいずれかに該当する場合は開示対象個人情報には該当しません。またお取引先様から委託される個人情報は開示対象個人情報に該当いたしませんが、委託元より特段の指示がある場合はその指示に従います。</p>
                                <p>a) 当該個人情報の存否が明らかになることによって、本人又は第三者の生命、身体又は財産に危害が及ぶおそれのあるもの</p>
                                <p>b) 当該個人情報の存否が明らかになることによって、違法又は不当な行為を助長し、又は誘発するおそれのあるもの</p>
                                <p>c) 当該個人情報の存否が明らかになることによって、国の安全が害されるおそれ、他国若しくは国際機関との信頼関係が損なわれるおそれ又は他国若しくは国際機関との交渉上不利益を被るおそれのあるもの</p>
                                <p>d) 当該個人情報の存否が明らかになることによって、犯罪の予防、鎮圧又は捜査その他の公共の安全と秩序維持に支障が及ぶおそれのあるもの</p>
                            </div>
                        </li>
                    </ol>
                </div>

                <div class="information">
                    <h3>【お問合せ窓口】</h3>
                    <p>個人情報に関するお問合せにつきましては、下記窓口で受付けております。</p>
                    <p class="bold">都市ガス電気  個人情報問合せ窓口</p>
                    <p>〒810-0001 福岡県福岡市中央区天神2丁目3-10天神パイクレント719</p>
                    <p>TEL: <a href="tel:0922875096" class="phone-number">092-287-5096</a></p>
                    <p>Email: toshigas-denki@kyusyu-com.site</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';

export default {
    name: "PrivacyVue",
    components: {
        PageTitle,
    },
    data() {
        return {
            title: "Privacy Policy",
            subTitle: "プライバシーポリシー"
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    .wrap {
        h2 {
            font-size: 20px;
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            border-left: 10px solid var(--sub1);
            margin-top: 30px;
            margin-bottom: 0;
            background-color: var(--gray);
            @media screen and (max-width: 767px) {
                padding-left: 10px;
            }
        }
        .privacy-content {
            padding: 20px 0 60px 0;
            @media screen and (max-width: 767px) {
                padding: 10px 0 30px 0;
            }
            ol {
                padding-left: 1em;
            }
        }
        .information {
            margin: 10px 0;
            padding: 10px;
            background-color: var(--gray);
            h3 {
                font-size: 18px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .indent {
            margin-left: 1em;
            @media screen and (max-width: 767px) {
                margin-left: 0.5em;
            }
        }
        .small {
            font-size: 14px;
            line-height: 20px;
        }
        .bold {
            font-weight: 500;
        }
        .right {
            text-align: end;
        }
        .right-content {
            width: 30%;
            margin-left: auto;
        }
        .my-10 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .mb-10 {
            margin-bottom: 10px;
        }
        .m-0 {
            margin: 0;
        }
    }
}
</style>