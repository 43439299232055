<template>
    <div class="page-title">
        <h1 class="title" v-html="title"></h1>
        <p class="sub-title" v-html="subTitle"></p>
    </div>
</template>

<script>
export default {
    name: "PageTitle",
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>
    .page-title {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        width: 100vw;
        height: 300px;
        padding-top: 70px;
        background-color: var(--sub3);
        .title {
            width: 100%;
            font-size: 50px;
            font-weight: 600;
            line-height: 65px;
            text-align: center;
            margin-bottom: 10px;
            color: var(--sub1);
        }
        .sub-title {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            color: var(--sub1);
        }
    }
</style>