<template>
    <section>
        <div class="wrap">
            <div class="title">
                <h2 id="term">約款</h2>
                <img src="@/assets/title-bar.webp" class="title-bar">
            </div>
            <p class="clause-text">低圧約款</p>
            <a href="/pdf/toshigas-denki_term.pdf" target="_blank" class="clause-btn">
                <img src="@/assets/file.webp">
                PDFを開く
            </a>
            <p class="clause-text">一人ぐらし生活サポート<br>一人ぐらし安心サポート 約款</p>
            <a href="/pdf/support_for_living_alone_term.pdf" target="_blank" class="clause-btn">
                <img src="@/assets/file.webp">
                PDFを開く
            </a>
        </div>
    </section>
</template>
<script>

export default {
    name: "ClauseVue"
}
</script>

<style lang="scss" scoped>
    section {
        padding: 130px 0;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
        padding: 60px 0;
        }
        @media screen and (max-width: 767px) {
        padding: 40px 0;
    }
        background-color: var(--gray);
        .wrap {
            .title {
                margin-bottom: 40px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 30px;
                }
            }
            .clause-text {
                font-size: 20px;
                font-weight: 400;
                text-align: center;
                margin-top: 40px;
                @media screen and (max-width: 767px) {
                    margin-top: 20px;
                    margin-bottom: 0;
                }
            }
            .clause-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 400px;
                height: 70px;
                padding: 20px 0;
                margin: 20px auto 0 auto;
                color: var(--white);
                background-color: var(--main);
                border-radius: 999px;
                &:hover {
                    cursor: pointer;
                    background-color: var(--sub1);
                }
                @media screen and (max-width: 767px) {
                    width: 100%;
                    height: 60px;
                    margin-top: 10px;
                }
                img {
                    width: auto;
                    height: 100%;
                    margin-right: 1rem;
                }
            }
        }
    }
</style>