<template>
    <section class="padding-y-150">
        <div class="title">
            <h2 id="contact">お問い合わせ</h2>
            <img src="@/assets/title-bar.webp" class="title-bar">
        </div>
        <div class="wrap">
            <div class="content">
                <h3 id="form">フォームからのお問い合わせは<br class="sp-only"/>24時間受付中です。</h3>
                <div v-if="displayType === '1'">
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">会社名・屋号名<br class="sp-only"/><span class="note">（ない場合は「なし」とご記入ください）</span></p>
                        <div class="hissu">必須</div>
                    </div>
                    <input
                        type="text"
                        v-model="formData.companyName"
                        class="form-content"
                        placeholder="山田商会"
                    />
                    <p class="error-message" v-if="validation">
                        {{ messageData.companyName }}
                    </p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">お名前</p>
                        <div class="hissu">必須</div>
                    </div>
                    <input
                        type="text"
                        v-model="formData.name"
                        class="form-content"
                        placeholder="山田 太郎"
                    />
                    <p class="error-message" v-if="validation">
                        {{ messageData.name }}
                    </p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">返信先メールアドレス</p>
                        <div class="hissu">必須</div>
                    </div>
                    <input
                        type="email"
                        v-model="formData.email"
                        class="form-content"
                        placeholder="sample@example.com"
                    />
                    <p class="error-message" v-if="validation">
                        {{ messageData.email }}
                    </p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">電話番号</p>
                        <div class="hissu">必須</div>
                    </div>
                    <input
                        type="text"
                        v-model="formData.tel"
                        class="form-content"
                        placeholder="0123456789（ハイフンなし）"
                    />
                    <p class="error-message" v-if="validation">{{ messageData.tel }}</p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">お問い合わせ内容</p>
                        <div class="hissu">必須</div>
                    </div>
                    <textarea
                        name="text"
                        id="text"
                        v-model="formData.text"
                        class="form-content"
                        placeholder="お問い合わせ内容を入力してください"
                    ></textarea>
                    <p class="error-message" v-if="validation">
                        {{ messageData.text }}
                    </p>
                    </div>
                    <button @click="checkValidate()">確認</button>
                </div>
                <div v-if="displayType === '2'">
                    <h4>入力内容確認</h4>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">会社名・屋号名</p>
                    </div>
                    <p class="check-content">{{ formData.companyName }}</p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">お名前</p>
                    </div>
                    <p class="check-content">{{ formData.name }}</p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">返信先メールアドレス</p>
                    </div>
                    <p class="check-content">{{ formData.email }}</p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">電話番号</p>
                    </div>
                    <p class="check-content">{{ formData.tel }}</p>
                    </div>
                    <div class="form-row">
                    <div class="form-text">
                        <p class="h-white">お問い合わせ内容</p>
                    </div>
                    <textarea
                        class="form-content"
                        v-model="formData.text"
                        readonly
                    ></textarea>
                    </div>
                    <div class="form-button">
                    <button @click="back()">戻る</button>
                    <button @click="send()">送信</button>
                    </div>
                </div>
                <div v-if="displayType === '3'">
                    <h4 class="complete-title">お問い合わせを承りました</h4>
                    <p class="complete-text">
                    お問い合わせを承りました。
                    お問い合わせ内容を、ご入力のメールアドレスに送信しました。
                    </p>
                    <p class="complete-text">
                    20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
                    </p>
                    <router-link class="complete-btn" to="/" v-scroll-to="'#app'" @click="formInit()">
                    サイトトップへ戻る
                    </router-link>
                </div>
                <img src="@/assets/ira007.webp" class="ira-img">
            </div>
        </div>
    </section>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from "axios";
  
  export default {
    name: "ContactVue",
    data() {
      return {
        displayType: "1",
        validation: false,
        emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        telPattern: /^0\d{9,10}$/,
        formData: {
          companyName: "",
          name: "",
          email: "",
          tel: "",
          text: "",
        },
        messageData: {
          companyName: "",
          name: "",
          email: "",
          tel: "",
          text: "",
        },
      };
    },
    computed: {
      ...mapGetters(["rootUrl"]),
    },
    methods: {
      checkValidate() {
        // 各入力項目の入力チェック
        this.validation = this.companyNameValid(this.formData.companyName);
        this.validation = this.nameValid(this.formData.name);
        this.validation = this.emailValid(this.formData.email);
        this.validation = this.telValid(this.formData.tel);
        this.validation = this.textValid(this.formData.text);
        // メッセージの状態チェック
        this.messageCheck();
        // バリデーションがfalseになっているか確認
        if (this.validation !== true) {
          this.displayType = "2";
          this.$scrollTo("#form", 500, { easing: "ease" });
        } else {
          // no action
        }
      },
      companyNameValid(companyName) {
        if (companyName !== "") {
          // ok
        } else {
          this.messageData.companyName = "会社名・屋号名を入力してください";
          return true;
        }
  
        // 初期化
        this.messageData.companyName = "";
        return this.validation !== false
          ? this.validation
          : (this.validation = false);
      },
      nameValid(name) {
        if (name !== "") {
          // ok
        } else {
          this.messageData.name = "お名前を入力してください";
          return true;
        }
  
        // 初期化
        this.messageData.name = "";
        return this.validation !== false
          ? this.validation
          : (this.validation = false);
      },
      emailValid(email) {
        if (email !== "") {
          if (this.emailPattern.test(email)) {
            // ok
          } else {
            this.messageData.email =
              "メールアドレスは正しい形式で入力してください。";
            return true;
          }
        } else {
          this.messageData.email = "メールアドレスを入力してください";
          return true;
        }
  
        // 初期化
        this.messageData.email = "";
        return this.validation !== false
          ? this.validation
          : (this.validation = false);
      },
      telValid(tel) {
        if (tel !== "") {
          if (this.telPattern.test(tel)) {
            // ok
          } else {
            this.messageData.tel = "正しい電話番号を入力してください";
            return true;
          }
        } else {
          this.messageData.tel = "電話番号を入力してください";
          return true;
        }
  
        // 初期化
        this.messageData.tel = "";
        return this.validation !== false
          ? this.validation
          : (this.validation = false);
      },
      textValid(text) {
        if (text !== "") {
          // ok
        } else {
          this.messageData.text = "お問い合わせ内容を入力してください";
          return true;
        }
  
        // 初期化
        this.messageData.text = "";
        return this.validation !== false
          ? this.validation
          : (this.validation = false);
      },
      messageCheck() {
        // メッセージが残っていなかったらバリデーションをfalseにする
        for (const key in this.messageData) {
          if (this.messageData[key] === "") {
            // ok
          } else {
            this.validation = true;
            this.$scrollTo("#form", 500, { easing: "ease" });
            break;
          }
          this.validation = false;
        }
      },
      //確認画面
      back() {
        this.displayType = "1";
        this.$scrollTo("#form", 500, { easing: "ease" });
      },
      // メール送信
      async send() {
        let params = new URLSearchParams();
        // パラメーター追加
        params.append("companyName", this.formData.companyName);
        params.append("name", this.formData.name);
        params.append("email", this.formData.email);
        params.append("tel", this.formData.tel);
        params.append("text", this.formData.text);
  
        console.log(this.rootUrl);
  
        axios
          .post(this.rootUrl.rootUrl + "/api/send.php", params)
          .then((response) => {
            if (response.status === 200) {
              this.displayType = '3';
              this.$scrollTo("#form", 500, { easing: "ease" });
              this.formData = {
                companyName: "",
                name: "",
                email: "",
                tel: "",
                contactType: "1",
                text: "",
              };
            } else {
              // no action
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      formInit() {
        this.displayType = "1";
        this.formData = {
          companyName: "",
          name: "",
          email: "",
          tel: "",
          contactType: "1",
          text: "",
        };
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  section {
    background-color: var(--gray);
    .description {
      color: var(--white);
    }
    @media screen and (max-width: 1100px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
    .title {
      margin-bottom: 40px;
    }
    .wrap {
        position: relative;
        background-color: var(--white);
        padding-top: 60px;
        padding-bottom: 40px;
        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 40px 0;
        }
        .content {
            h3 {
            color: var(--white);
            background-color: var(--main);
            padding: 15px 0;
            margin-bottom: 40px;
            text-align: center;
            @media screen and (max-width: 1150px) {
                margin-bottom: 40px;
            }
            @media screen and (max-width: 767px) {
                font-size: 18px;
                padding: 10px 0;
                margin-top: 0;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 350px) {
              font-size: 16px;
            }
            }
            h4 {
            color: var(--font);
            font-size: 20px;
            text-align: center;
            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
            }
            .form-info {
            text-align: center;
            margin-bottom: 40px;
            margin-left: 20px;
            margin-right: 20px;
            @media screen and (max-width: 1150px) {
                margin-bottom: 20px;
            }
            }
            .form-row {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
            .form-text {
                display: flex;
                .hissu {
                font-size: 20px;
                line-height: 20px;
                padding: 5px 0;
                text-align: center;
                color: var(--white);
                background-color: var(--sub1);
                width: 10%;
                @media screen and (max-width: 767px) {
                    width: 20%;
                    height: 30px;
                    margin-top: auto;
                }
                }
                p {
                font-size: 18px;
                font-weight: 400;
                width: 90%;
                margin: 0;
                color: var(--font);
                @media screen and (max-width: 767px) {
                    width: 80%;
                }
                .note {
                    font-size: 14px;
                    @media screen and (max-width: 767px) {
                    font-size: 12px;
                    line-height: 12px;
                    }
                }
                }
            }
            .form-content {
                font-size: 20px;
                width: 100%;
                color: var(--font);
                background-color: var(--white);
                padding: 10px 10px;
                margin-top: 5px;
                border: 1px solid var(--font);
                border-radius: 5px;
                @media screen and (max-width: 767px) {
                font-size: 16px;
                margin-top: 5px;
                }
            }
            select {
                height: 45px;
                color: var(--font);
                background-color: var(--white);
                border: 1px solid var(--main);
                border-radius: 5px;
                cursor: pointer;
                &:-ms-expand {
                display: none; /* デフォルトの矢印を非表示(IE用) */
                }
                @media screen and (max-width: 767px) {
                height: 40px;
                }
            }
            .error-message {
                font-size: 18px;
                color: red;
                line-height: 1.5rem;
                margin: 0;
                @media screen and (max-width: 767px) {
                font-size: 16px;
                }
            }
            .check-content {
                font-size: 18px;
                color: var(--font);
                background-color: var(--white);
                width: 100%;
                height: 46px;
                padding: 10px 10px;
                border: 1px solid var(--font);
                margin-top: 10px;
                @media screen and (max-width: 767px) {
                font-size: 16px;
                margin-top: 5px;
                height: 44px;
                }
            }
            textarea {
                height: 150px;
                resize: none;
            }
            }
            button,
            .complete-btn {
            display: block;
            font-size: 18px;
            font-weight: 500;
            width: 30%;
            height: 60px;
            cursor: pointer;
            margin: 0 auto;
            color: var(--font);
            background-color: var(--white);
            border: 1px solid var(--font);
            &:hover {
                color: var(--white);
                background-color: var(--sub1);
                border: 1px solid var(--sub1);
            }
            @media screen and (max-width: 767px) {
                padding: 10px 0;
                width: 40%;
                height: 50px;
            }
            }
            .form-button {
            display: flex;
            justify-content: space-between;
            }
            .phone-number {
            display: block;
            font-size: 70px;
            line-height: 70px;
            font-weight: 500;
            color: var(--main);
            text-align: center;
            text-decoration: none;
            @media screen and (min-width: 768px) {
                // 現話番号のクリックを無効化する
                pointer-events: none;
                cursor: default;
            }
            @media screen and (max-width: 767px) {
                font-size: 38px;
                line-height: 38px;
                text-wrap: nowrap;
            }
            }
            .business-hours {
            font-size: 25px;
            color: var(--main);
            margin-top: 20px;
            text-align: center;
            @media screen and (max-width: 767px) {
                margin-top: 10px;
                font-size: 18px;
            }
            }
            .complete-title {
            text-align: center;
            }
            .complete-text {
            margin: 0;
            text-align: center;
            @media screen and (max-width: 767px) {
                text-align: start;
            }
            }
            .complete-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 60px;
            padding: 0;
            @media screen and (max-width: 767px) {
                width: 70%;
            }
            }
        }
        .ira-img {
            width: auto;
            height: 150px;
            margin-left: auto;
            @media screen and (max-width: 767px) {
              margin-top: 10px;
            }
        }
    }
    .mragin-top-80 {
        margin-top: 80px;
        @media screen and (max-width: 767px) {
            margin-top: 40px;
        }
    }
    #phone {
      padding-bottom: 20px;
    }
    #fax {
      @media screen and (max-width: 767px) {
        padding-top: 25px;
        padding-bottom: 40px;
      }
    }
  }
  </style>
  