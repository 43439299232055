<template>
    <div class="demerit-contents">
        <img src="@/assets/icon009.webp" alt="市場連動型プランのデメリット" class="demerit-img pc-tablet-only">
        <img src="@/assets/icon009_sp.webp" alt="市場連動型プランのデメリット" class="demerit-img sp-only">
        <div class="demerit-text">
            市場連動型プランのデメリットは、市場の影響を直接受けることによる変動リスクです。年間を通せば安定はしているものの、夏場など⼀時的にスポットプライスが⾼騰した場合、電気代がその時期だけでみると割⾼になる可能性があります。市場連動型プランは、変動リスクがあることを理解してください。
        </div>
    </div>
</template>

<script>
export default {
    name: "DemeritVue"
}
</script>

<style lang="scss" scoped>
    .demerit-contents {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 40px;
        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
        .demerit-img {
            width: 45%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
        .demerit-text {
            display: flex;
            align-items: center;
            width: 54%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
</style>