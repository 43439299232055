<template>
  <div>
    <FirstView />
    <AboutUs />
    <ChangeVue />
    <PriceVue />
    <ClauseVue />
    <NewsVue />
    <ContactVue />
    <CampanyVue />
  </div>
</template>

<script>
import FirstView from '@/components/Home/FirstView.vue';
import AboutUs from '@/components/Home/AboutUs.vue';
import ChangeVue from '@/components/Home/Change.vue';
import PriceVue from '@/components/Home/Price.vue';
import ClauseVue from '@/components/Home/Clause.vue';
import NewsVue from '@/components/Home/News.vue';
import ContactVue from '@/components/Home/Contact.vue';
import CampanyVue from '@/components/Home/Campany.vue';

export default {
  name: 'HomeView',
  components: {
    FirstView,
    AboutUs,
    ChangeVue,
    PriceVue,
    ClauseVue,
    NewsVue,
    ContactVue,
    CampanyVue,
  }
}
</script>
