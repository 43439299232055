<template>
  <section>
    <div class="wrap">
      <div class="title">
        <h1 id="about">
          都市ガス電気とは
        </h1>
        <img src="@/assets/title-bar.webp" class="title-bar">
      </div>
      <div class="message">
        <p>都市ガス電気は、今話題の新電力と、お客様の生活をサポートするサービスを提供しています。ご家庭からオフィスに至るまで全てのお客さまに安心・安全な電力を365日、お届けし、豊かな生活をサポートいたします。</p>
        <img src="@/assets/ira001.webp" class="ira-img">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style lang="scss" scoped>
  section {
    @media screen and (max-width: 767px) {
      padding: 50px 0;
    }
    .wrap {
      .message {
        position: relative;
        margin-top: 40px;
        @media screen and (max-width: 767px) {
          margin-top: 25px;
        }
        p {
          width: 80%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        .ira-img {
          position: absolute;
          top: -120px;
          right: 0;
          width: 25%;
          height: auto;
          @media screen and (max-width: 767px) {
            position: static;
            width: 50%;
            margin: 20px auto 0 auto;
          }
        }
      }
    }
  }
</style>