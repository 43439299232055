<template>
    <div class="comparison-tokyo">
      <h4 id="comparison-tokyo">
        東京電力との比較 40Aの場合
        <hr>
      </h4>
      <div class="company-title main">都市ガス電気</div>
      <div class="company-list">
        <div class="company-basic-charge">
          <div class="title sub2">基本料金</div>
          <div class="description sub3">
            <span class="description-text">1121.91円</span>
            <img src="@/assets/pa005.webp" class="description-img">
          </div>
        </div>
        <div class="company-electricity-charge">
          <div class="title sub2">電力量料金</div>
          <div class="description">
            <div class="company-row border">
              <div class="energy gray">〜120 kwh</div>
              <div class="price sub3">
                <span>28.50円</span>
                <img src="@/assets/pa005.webp" class="price-img">
              </div>
            </div>
            <div class="company-row border">
              <div class="energy gray">121〜200 kwh</div>
              <div class="price sub3">
                <span>34.77円</span>
                <img src="@/assets/pa005.webp" class="price-img">
              </div>
            </div>
            <div class="company-row border">
              <div class="energy gray">201〜300 kwh</div>
              <div class="price sub3">
                <span>34.77円</span>
                <img src="@/assets/pa005.webp" class="price-img">
              </div>
            </div>
            <div class="company-row">
              <div class="energy gray">301 kwh〜</div>
              <div class="price sub3">
                <span>38.66円</span>
                <img src="@/assets/pa005.webp" class="price-img">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="company-title">東京電力</div>
      <div class="company-list">
        <div class="company-basic-charge">
          <div class="title gray-text">基本料金</div>
          <div class="description gray">
            <span class="description-text">1180.96円</span>
          </div>
        </div>
        <div class="company-electricity-charge">
          <div class="title gray-text">電力量料金</div>
          <div class="description gray">
            <div class="company-row border">
              <div class="energy">〜120 kwh</div>
              <div class="price">30.00円</div>
            </div>
            <div class="company-row border">
              <div class="energy">121〜200 kwh</div>
              <div class="price">36.60円</div>
            </div>
            <div class="company-row border">
              <div class="energy gray">201〜300 kwh</div>
              <div class="price">36.60円</div>
            </div>
            <div class="company-row">
              <div class="energy">301 kwh〜</div>
              <div class="price">40.69円</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "ComparisonTokyo"
}
</script>

<style lang="scss" scoped>
.comparison-tokyo {
    h4 {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin: 80px 0 30px 0;
    color: var(--font);
    @media screen and (max-width: 1100px) {
        font-size: 20px;
        font-weight: 500;
    }
    @media screen and (max-width: 1100px) {
        margin: 80px 0 20px 0;
    }
    hr {
        width: 45px;
        margin: 20px auto 0 auto;
        border-color: var(--main);
        @media screen and (max-width: 1100px) {
        margin: 10px auto 30px auto;
        }
        @media screen and (max-width: 1100px) {
            margin: 10px auto 20px auto;
        }
    }
    }
    .company-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        font-size: 20px;
        color: var(--white);
        background-color: var(--gray-drak);
        margin-top: 50px;
        @media screen and (max-width: 1100px) {
            margin-top: 30px;
        }
    }
    .company-list {
        display: flex;
        .company-basic-charge, .company-electricity-charge {
            .title, .description {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-weight: 600;
            }
            .title {
            height: 40px;
            font-weight: 400;
            color: var(--white);
            margin: 0;
            }
            .description {
                justify-content: center;
                align-content: center;
                flex-wrap: wrap;
                height: 200px;
                color: var(--font);
                @media screen and (max-width: 767px) {
                    height: 220px;
                }
                .description-text {
                    width: 100%;
                    text-align: center;
                    color: var(--font);
                }
                .description-img {
                    width: auto;
                    height: 30px;
                    margin-top: 5px;
                }
                .company-row {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    width: 100%;
                    font-weight: 500;
                    @media screen and (max-width: 767px) {
                        height: 55px;
                    }
                    .energy, .price {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        width: 100%;
                        height: 100%;
                        width: 50%;
                    }
                    .price {
                        position: relative;
                        @media screen and (max-width: 767px) {
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            flex-wrap: wrap;
                        }
                        .price-img {
                            position: absolute;
                            top: 10px;
                            right: 20px;
                            width: auto;
                            height: 30px;
                            @media screen and (min-width: 768px) and (max-width: 1100px) {
                                top: 12.5px;
                                right: 5px;
                                height: 25px;
                            }
                            @media screen and (max-width: 767px) {
                                position: static;
                                height: 20px;
                            }
                        }
                    }
                }
                .border {
                    border-bottom: 2px solid var(--white);
                }
            }
        }
        .company-basic-charge {
            width: 40%;
            border-right: 2px solid var(--white);
            .description {
            font-size: 22px;
            @media screen and (max-width: 767px) {
                font-size: 18px;
            }
            .discount-img {
                width: 50px;
                margin-left: 10px;
                @media screen and (max-width: 767px) {
                    width: 40px;
                }
            }
            span {
                color: var(--sub1);
            }
            }
        }
        .company-electricity-charge {
        width: 60%;
        .description {
        flex-wrap: wrap;
        font-size: 16px;
        .company-row {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 100%;
            @media screen and (max-width: 767px) {
              height: 55px;
            }
            .energy, .price {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 50%;
              line-height: 20px;
            }
        }
        .border {
            border-bottom: 2px solid var(--white);
        }
        }
        }
    }
    .main {
        background-color: var(--main);
    }
    .sub2 {
        background-color: var(--sub2);
    }
    .sub3 {
        background-color: var(--sub3);
    }
    .gray-text {
        background-color: var(--gray-text);
    }
    .gray {
        background-color: var(--gray);
    }
}
</style>