<template>
  <div>
    <div class="fv">
      <img src="@/assets/main001.webp" alt="新電力への乗り換えなら「都市ガス電気」" class="fv-img">
      <img src="@/assets/main001-sp.webp" alt="新電力への乗り換えなら「都市ガス電気」" class="fv-sp-img">
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstView"
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@400;500;600;700;900&display=swap');

  .fv {
    position: relative;
    height: calc(100svh - 70px);
    margin-top: 70px;
    overflow: hidden;
    background-color: var(--white);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 100svw;
      height: auto;
      // aspect-ratio: 16 / 9;
    }
    .fv-img {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .fv-sp-img {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
</style>