<template>
    <section>
        <div class="wrap">
            <div class="title">
                <h2 id="campany">会社情報</h2>
                <img src="@/assets/title-bar.webp" class="title-bar">
            </div>

            <div class="list">
                <div class="row">
                    <div class="title">会社名</div>
                    <div class="description">通信基地局株式会社</div>
                </div>
                <div class="row">
                    <div class="title">住所</div>
                    <div class="description">〒 810-0001<br><p class="address">福岡県福岡市中央区天神二丁目3番10号 天神パインクレスト719号</p></div>
                </div>
                <div class="row">
                    <div class="title">電話番号</div>
                    <div class="description"><a href="tel:05017827337" class="phone-number">050-1782-7337</a></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "CampanyVue",
}
</script>

<style lang="scss" scoped>
    section {
        .wrap {
            .title {
                margin-bottom: 40px;
            }
            .list {
                width: 60%;
                margin: 0 auto;
                border-top: 1px solid var(--font);
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                .row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;
                    border-bottom: 1px solid var(--font);
                    .title, .description {
                        display: flex;
                        align-items: center;
                        min-height: 50px;
                        margin: 0;
                    }
                    .title {
                        justify-content: center;
                        width: 25%;
                        @media screen and (max-width: 767px) {
                            width: 30%;
                        }
                    }
                    .description {
                        flex-wrap: wrap;
                        width: 75%;
                        padding-left: 5%;
                        padding-right: 5px;
                        border-left: 1px solid var(--font);
                        @media screen and (max-width: 767px) {
                            width: 70%;
                        }
                        .address {
                            width: 100%;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
</style>