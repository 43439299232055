<template>
    <section>
        <div class="wrap">
            <div class="title">
                <h2 id="news">お知らせ</h2>
                <img src="@/assets/title-bar.webp" class="title-bar">
                <img src="@/assets/ira006.webp" class="ira-img">
            </div>
            <div class="news-title-row">
                <span class="date">2024年9月27日</span>ホームページを開設のお知らせ。
            </div>
            <div class="news-row">
                ホームページを開設しました。
            </div>
            <!-- <div class="news-title-row">
                <span class="date">2024年〇〇月〇〇日</span>〇〇〇〇〇〇〇〇〇〇〇〇
            </div>
            <div class="news-row">
                〇〇〇〇〇〇〇〇〇〇〇〇
            </div> -->
        </div>
    </section>
</template>

<script>
export default {
    name: "NewsVue"
}
</script>

<style lang="scss" scoped>
    section {
        .wrap {
            .title {
                position: relative;
                margin-bottom: 40px;
                .ira-img {
                    position: absolute;
                    top: -20px;
                    right: 0;
                    width: 200px;
                    @media screen and (max-width: 767px) {
                        width: 100px;
                    }
                    @media screen and (max-width: 350px) {
                        width: 80px;
                    }
                }
            }
            .news-title-row {
                display: flex;
                align-items: center;
                height: 60px;
                font-size: 20px;
                font-weight: 500;
                padding-left: 60px;
                background-color: #DAEFFE;
                @media screen and (max-width: 767px) {
                    flex-wrap: wrap;
                    align-content: center;
                    font-size: 18px;
                    line-height: 20px;
                    padding: 0 10px;
                }
                @media screen and (max-width: 350px) {
                    height: auto;
                    font-weight: 16px;
                    padding: 10px 5px;
                }
                .date {
                    margin-right: 1em;
                    @media screen and (max-width: 767px) {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;
                    }
                }
            }
            .news-row {
                display: flex;
                align-content: center;
                align-items: center;
                height: 45px;
                font-size: 20px;
                padding-left: 60px;
                margin-bottom: 20px;
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 20px;
                    padding: 0 10px;
                }
            }
        }
    }
</style>