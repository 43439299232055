<template>
  <Header />
  <router-view/>
  <img
    src="@/assets/to-top.webp"
    alt="TOPへ戻る"
    class="top-scroll"
    v-if="showScrollButton"
    v-scroll-to="'#app'"
  />
  <Footer />
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showScrollButton: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showScrollButton = scrollTop > 200;
    },
  },
}
</script>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

  /*---------------------
  共通初期設定
  ---------------------*/
  :root {
    --main: #00B7EE;
    --sub1: #0083E0;
    --sub2: #8DDFF7;
    --sub3: #DAF4FC;
    --font: #282828;
    --cover: rgb(40, 155, 190, 0.7);
    --white: #FFFFFF;
    --gray: #F0F0F0;
    --gray-text: #A9A9A9;
    --gray-drak: #808080;
    --shadow: #b8b8b8;
  }
  #app {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
    color: var(--font);
    line-height: 1.7em;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
  html * {
    box-sizing: border-box !important;
  }
  body {
    margin: 0;
  }
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
  h1,h2,h3,h4 {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: var(--font);
    cursor: pointer;
  }
  p {
    margin: 0;
  }
  /*---------------------
  Section
  ---------------------*/
  section {
    padding: 150px 0;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      padding: 80px 0;
    }
    @media screen and (max-width: 767px) {
      padding: 60px 0;
    }
    .title {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      h1, h2 {
        width: 100%;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 40px;
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 23px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
      .title-bar {
        width: 130px;
        @media screen and (max-width: 767px) {
          width: 100px;
        }
      }
    }
    .sub-title {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      background-color: var(--gray);
      h3 {
        color: var(--white);
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
  }
  .wrap {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    @media screen and (max-width: 1100px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .content {
      width: 800px;
      margin: 0 auto;
      @media screen and (max-width: 1100px) {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        margin: 0;
      }
    }
  }
  /*---------------------
  TOPへ戻るスクロールボタン
  ---------------------*/
  .top-scroll {
    width: 70px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 3;
    opacity: 0.9;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      width: 50px;
      right: 0;
      bottom: 0;
      // bottom: 60px; // SPオーバーレイがある場合はこっち
    }
  }
  /*---------------------
  電話番号 クリック設定
  ---------------------*/
  .phone-number {
    @media screen and (min-width: 768px) {
      // 電話番号のクリックを無効化する
      display: inline;
      text-wrap: nowrap;
      pointer-events: none;
      cursor: default;
    }
  }
  /*---------------------
  display 設定
  ---------------------*/
  .pc-only {
    @media screen and (max-width: 1100px) {
      display: none;
    }
  }
  .pc-tablet-only {
    display: none;
    @media screen and (min-width: 768px) {
      display: initial !important;
    }
  }
  .tablet-only {
    display: none;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      display: initial !important;
    }
  }
  .sp-only {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial !important;
    }
  }
</style>
