import { createRouter, createWebHistory } from 'vue-router';
import { setTitle, setDescription } from "@/mixins/index.js";
import store from "@/store";

import HomeView from '../views/HomeView.vue';
import PrivacyVue from '../views/Privacy.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "都市ガス電気ホームページ",
      description:
        "都市ガス電気は、今話題の新電力と、お客様の生活をサポートするサービスを提供しています。ご家庭からオフィスに至るまで全てのお客さまに安心・安全な電力を365日、お届けし、豊かな生活をサポートいたします。",
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyVue,
    meta: {
      title: "プライバシーポリシー｜都市ガス電気",
      description:
        "都市ガス電気のプライバシーポリシーです。",
    },
  },
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition === true) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName("head");
  let gfjScript = document.createElement("script");
  gfjScript.type = "application/ld+json";
  if (to.meta.gfjScriptContent !== undefined) {
    gfjScript.innerHTML = to.meta.gfjScriptContent;
    documentHead[0].appendChild(gfjScript);
    //↑gfj用
  }
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router;
